export default (function () {
    const cordialForms = document.querySelectorAll('.cordial-form')

    if (!cordialForms) return

    cordialForms.forEach((cordialForm) => {
        const cordialSuccess = document.querySelector(`#${cordialForm.id}-success`)

        if (!cordialSuccess) return

        // Get some data bound to the form, so we can decide what to send.
        const formType = cordialForm.getAttribute('data-cordial-form')
        const formSource = cordialForm.getAttribute('data-cordial-source')

        const handleCordialSubmit = (event) => {
            event.preventDefault()
            const optInDate = new Date().toISOString()
            const formData = new FormData(event.target)

            /** @var crdl **/
            if (!crdl) return

            // Construct data in the format Cordial expects us to send.
            const authData = {
                email: formData.get('email'),
            }

            const subscribeData = {
                'channels.email.subscribeStatus': 'subscribed',
            }

            const contactData = {
                forceSubscribe: {
                    email: true,
                },
                upsert: true,
            }

            if (formType === 'professional') {
                contactData['source'] = 'hcp'
                contactData['HCP_optin_date'] = optInDate
                contactData['HCP_HealthCareProfessionals'] = true
            } else {
                contactData['source'] = formSource
                contactData['opt-in-date'] = optInDate
                contactData['Omron_Subscribers'] = true
            }

            // Send the data to Cordial and toggle success notifcation.
            crdl('contact', authData, subscribeData, contactData)
            cordialForm.classList.add('hidden')
            cordialSuccess.classList.remove('hidden')
        }

        cordialForm.addEventListener('submit', handleCordialSubmit)
    })
})
