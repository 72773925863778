import { ariaGetControlledElement } from '@vo/js/utils/aria'
import Cookies from 'js-cookie'

export default (function () {
    const closeButton = document.querySelector('[aria-controls="newsletter-popup"]')
    const popup = ariaGetControlledElement(closeButton)

    if (!closeButton || !popup) return

    const openPopup = () => {
        document.body.classList.add('overflow-hidden')
        popup.classList.remove('opacity-0', 'invisible', 'pointer-events-none')
    }

    const closePopup = () => {
        document.body.classList.remove('overflow-hidden')
        popup.classList.add('opacity-0', 'invisible', 'pointer-events-none')
    }

    const newsletterPopupClosed = Cookies.get('newsletterPopupClosed')

    if (!newsletterPopupClosed) {
        setTimeout(() => openPopup(), 500)
        Cookies.set('newsletterPopupClosed', 'true', { expires: 1 })
    }

    closeButton.addEventListener('click', () => closePopup())

    popup.addEventListener('click', (event) => {
        const exceptions = ['#newsletter-popup-inner', '#language-switch-popup-inner', '#CybotCookiebotDialog', '#CybotCookiebotDialogBodyUnderlay', '#onetrust-consent-sdk']
        let closeAllowed = true

        exceptions.forEach(exception => {
            if (event.target.closest(exception)) {
                closeAllowed = false
            }
        })

        if (closeAllowed) {
            closePopup()
        }
    })
})
