import Cookies from 'js-cookie'

async function createHash(data) {
    const encoder = new TextEncoder()
    const dataBuffer = encoder.encode(data)
    const hashBuffer = await crypto.subtle.digest('sha-256', dataBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

export const tagManagerEvents = async () => {
    window.dataLayer = window.dataLayer || []
    const data = window.gtmData

    const buttons = document.querySelectorAll('.button')

    // 1. Pageview
    // Number of page views your visitors have seen since landing on your website.
    if (data.user.email !== null) {
        // Check if currently logged in user (highest priority)
        Cookies.set('gtmEmail', data.user.email, { expires: 1 })
    }
    const gtmEmail = Cookies.get('gtmEmail') ? Cookies.get('gtmEmail').trim().toLowerCase() : null
    const gtmHashedEmail = gtmEmail ? await createHash(gtmEmail) : null

    window.dataLayer.push({
        event: 'pageview',
        page_type: data.page_type,
        user: {
            id: data.user.id,
            logged_in: data.user.logged_in,
            hashedemail: gtmHashedEmail,
            email: gtmEmail,
        },
        site: {
            environment: data.site.environment,
            name: data.site.name,
        },
    })

    // 2. Header Clicks
    // Trigger this event when any element of the header is clicked. Include only If exists
    // in the site: search, sign in icon, the logo, the wishlist icon, the cart icon, etc.
    const headerLinks = document.querySelectorAll('header a')
    headerLinks.forEach(link => {
        link.addEventListener('click', () => {
            let navCount = 1
            let parent = link.parentElement

            // Traverse parents up and count the number of 'nav' elements
            while (parent) {
                if (parent.tagName.toLowerCase() === 'nav') {
                    navCount++
                }
                parent = parent.parentElement
            }

            window.dataLayer.push({
                event: 'header_click',
                link: {
                    element: link.innerText,
                    level: `Level ${navCount}`,
                },
            })
        })
    })

    // 3. Footer Clicks
    // Trigger this event when any element of the footer is clicked.
    const footerLinks = document.querySelectorAll('footer a')
    footerLinks.forEach(link => {
        link.addEventListener('click', () => {
            window.dataLayer.push({
                event: 'footer_click',
                link: {
                    element: link.innerText,
                    url: link.href,
                },
            })
        })
    })

    // 4. Forms
    const forms = document.querySelectorAll('form')
    forms.forEach((form) => {
        const formName = getFormName(form)
        if (formName !== null) {
            // Form first-interact
            let firstInteraction = false
            const handleFirstInteraction = () => {
                if (!firstInteraction) {
                    firstInteraction = true

                    window.dataLayer.push({
                        event: 'form_tracking',
                        form: {
                            name: formName,
                            status: 'First Interaction',
                            error_message: '',
                        },
                    })

                    form.removeEventListener('input', handleFirstInteraction)
                    form.removeEventListener('focusin', handleFirstInteraction)
                }
            }

            form.addEventListener('input', handleFirstInteraction)
            form.addEventListener('focusin', handleFirstInteraction)

            // Non-ajax form submit
            form.addEventListener('submit', () => {
                gtmHandleFormSubmit(form)
            })
        }
    })

    // 5. Button Click
    // Trigger this event when the buttons on the sections listed down below are clicked (even if
    // it's not the <button> tag, so it can be an anchor that works and looks like a button).
    buttons.forEach(button => {
        button.addEventListener('click', () => {
            window.dataLayer.push({
                event: 'button_click',
                button: {
                    name: button.innerText,
                    section: data.page_type,
                },
            })
        })
    })
}

const getFormName = (form) => {
    window.dataLayer = window.dataLayer || []
    const data = window.gtmData

    const formAction = form.getAttribute('action')
    if (!formAction) return null

    const slug = formAction.split('/').pop()
    return data.forms[slug] || null
}

export const gtmHandleFormSubmit = (form) => {
    window.dataLayer = window.dataLayer || []
    const formName = getFormName(form)

    if (formName !== null) {
        window.dataLayer.push({
            event: 'form_tracking',
            form: {
                name: formName,
                status: 'Success',
                error_message: '',
            },
        })

        // Store email in cookie
        form.querySelectorAll('input[type=email]').forEach(emailField => {
            if (Cookies.get('gtmEmail') === undefined) {
                Cookies.set('gtmEmail', emailField.value, { expires: 1 })
            }
        })
    }
}

export const gtmHandleFormError = (form, errors) => {
    window.dataLayer = window.dataLayer || []
    const formName = getFormName(form)

    if (formName !== null) {
        form.addEventListener('submit', () => {
            window.dataLayer.push({
                event: 'form_tracking',
                form: {
                    name: formName,
                    status: 'Error',
                    error_message: errors.join('|'),
                },
            })
        })
    }
}

export default (function () {
    if (window.gtmData?.enabled === true) {
        tagManagerEvents()
    }
})
