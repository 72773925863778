export default (function () {
    const selects = document.querySelectorAll('[data-download-select]')

    if (!selects.length) return

    selects.forEach((section) => {
        const select = section.querySelector('select')
        const downloadButton = section.querySelector('a')

        const selectedOption = select.selectedOptions[0]
        if (selectedOption && selectedOption !== select.options[0]) {
            const fileURL = selectedOption.dataset.href
            downloadButton.setAttribute('href', fileURL)
            downloadButton.removeAttribute('disabled')
        }

        select.addEventListener('change', (e) => {
            const target = e.target
            const selectedOption = e.target.options[target.selectedIndex]
            const fileURL = selectedOption.dataset.href

            downloadButton.setAttribute('href', fileURL)
            downloadButton.removeAttribute('disabled')
        })
    })
})
