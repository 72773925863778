import { tns } from 'tiny-slider'
import breakpoints from '../../../breakpoints.json'

class tinySliders {
    static carousels = document.querySelectorAll('[data-module="carousel"]')
    static mobileCarousels = document.querySelectorAll('[data-module="carousel-mobile"]')
    static mq = window.matchMedia(`(min-width: ${breakpoints.lg}px)`)
    static instances = []
    static mobileInstances = []

    static init () {
        this.createIntances()
        this.updateMobileInstances()
        this.addEventListeners()
        this.createConsumersFeature()
        this.createTextMedia()
        this.productDetailImages()
        this.createRelatedPosts()
    }

    static addEventListeners () {
        this.handleChangeMediaQuery()
    }

    static createIntances () {
        this.carousels.forEach(instance => {
            const carouselId = instance.getAttribute('id')
            const type = instance.dataset.carouselType
            const options = this.getCarouselOptions(carouselId, type)

            if (!carouselId) return

            const slider = tns({
                container: '#' + carouselId,
                ...options
            })

            this.instances.push(slider)
        })
    }

    static createMobileInstances () {
        this.mobileCarousels.forEach(instance => {
            const carouselId = instance.getAttribute('id')
            const type = instance.dataset.carouselType
            const options = this.getCarouselOptions(carouselId, type)

            if (!carouselId) return

            const slider = tns({
                container: '#' + carouselId,
                ...options
            })

            this.mobileInstances.push(slider)
        })
    }

    static updateMobileInstances () {
        if (this.mq.matches) {
            this.mobileInstances.forEach(instance => {
                if (instance && instance.destroy !== null) {
                    instance.destroy()
                }
            })
            this.mobileInstances = []
        } else {
            if (!this.mobileInstances[0]) {
                this.createMobileInstances()
            }
        }
    }

    static handleChangeMediaQuery () {
        this.mq.onchange = () => {
            this.updateMobileInstances()
        }
    }

    static connectInstances(leadingInstances, secondaryInstances) {
        leadingInstances.forEach((instance, index) => {
            instance.events.on('indexChanged', (info) => {
                const secondInstance = secondaryInstances[index]
                if (secondInstance) {
                    secondInstance.goTo(info.index)
                }
            })
        })
    }

    static getSliderProgressThumb(container) {
        const outer = container.closest('.tns-outer')
        return outer.nextElementSibling.querySelector('.slider__progress-thumb')
    }

    static getOffset(element) {
        const transform = element.style.transform
        const regex = /translate3d\((.*)px,/
        const match = transform.match(regex)
        const offset = match[1]
        return parseInt(offset)
    }

    static getCarouselOptions (carouselId = null, type = '') {
        if (!carouselId) return

        // Default options
        let options = {
            mode: 'carousel',
            items: 1,
            gutter: '24',
            slideBy: 1,
            autoplay: false,
            center: false,
            controls: false,
            nav: false,
            mouseDrag: true,
            touch: true,
            autoplayButtonOutput: false,
            responsive: {
                786: {
                    items: 3
                },
                1024: {
                    items: 4
                }
            }
        }

        if (type === 'consumers-banner-grid') {
            options = {
                mode: 'carousel',
                items: 1,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: false,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
                responsive: {
                    786: {
                        items: 2
                    }
                }
            }
        }

        if (type === 'consumers-product-tabs' || type === 'consumers-featured-product-categories') {
            options = {
                mode: 'carousel',
                items: 1,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: false,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
                responsive: {
                    786: {
                        items: 2
                    }
                }
            }
        }

        if (type === 'consumers-featured-products') {
            options = {
                mode: 'carousel',
                items: 1,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: false,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
                responsive: {
                    786: {
                        items: 2
                    }
                }
            }
        }

        if (type === 'device-compatibility-products') {
            options = {
                mode: 'carousel',
                items: 1,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: false,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
            }
        }

        if (type === 'viso-partners') {
            options = {
                mode: 'carousel',
                items: 3,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: true,
                controlsContainer: document.querySelector('[aria-label="controls-'+ carouselId +'"]'),
                nav: false,
                mouseDrag: true,
                touch: true,
                responsive: {
                    786: {
                        items: 4
                    },
                    1024: {
                        items: 5,
                        gutter: '40',
                    }
                }
            }
        }

        if (type === 'viso-animations') {
            options = {
                mode: 'carousel',
                items: 1,
                gutter: '24',
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: true,
                controlsContainer: document.querySelector('[aria-label="controls-'+ carouselId +'"]'),
                nav: true,
                navPosition: 'bottom',
                navContainer: document.querySelector('[aria-label="nav-'+ carouselId +'"]'),
                mouseDrag: true,
                touch: true,
            }
        }

        if (type === 'consumers-testimonial') {
            options = {
                items: 1,
                mouseDrag: true,
                loop: false,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
            }
        }

        if (type === 'professionals-past-webinars') {
            options = {
                items: 1,
                mouseDrag: true,
                loop: true,
                slideBy: 1,
                navAsThumbnails: true,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
                gutter: '32',
                responsive: {
                    786: {
                        items: 2,
                    },
                    1024: {
                        items: 4,
                    }
                }
            }
        }

        if (type === 'professionals-upcoming-webinars') {
            options = {
                items: 1,
                gutter: '32',
                autoHeight: true,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
            }
        }

        if (type === 'professionals-featured-products') {
            options = {
                items: 1,
                gutter: '32',
                navAsThumbnails: true,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
            }
        }

        if (type === 'blog-category-featured') {
            options = {
                items: 1,
                gutter: '32',
                navAsThumbnails: true,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
                responsive: {
                    786: {
                        items: 2,
                    }
                }
            }
        }

        if (type === 'webinar-video-program') {
            options = {
                items: 1,
                gutter: '32',
                nav: false,
                controlsPosition: 'bottom',
                autoHeight: true,
                loop: false,
                touch: false,
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
            }
        }

        if (type === 'product-details-extra-features') {
            options = {
                mode: 'carousel',
                items: 1,
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: true,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
                autoHeight: true,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
            }
        }

        if (type === 'product-details-extra-media') {
            options = {
                mode: 'carousel',
                items: 1,
                slideBy: 1,
                autoplay: false,
                center: false,
                controls: false,
                nav: true,
                navAsThumbnails: true,
                navPosition: 'bottom',
                mouseDrag: true,
                touch: true,
                autoplayButtonOutput: false,
                autoHeight: true,
            }
        }

        return {
            ...options,
            nonce: window.customData.cspNonce
        }
    }

    static productDetailImages () {
        const imagesCarousel = document.querySelector('#product-detail-images')
        const thumbnailsCarousel = document.querySelector('#product-detail-thumbnails')

        if (!imagesCarousel || !thumbnailsCarousel) return

        // Create images carousel
        const imagesCarouselId = imagesCarousel.getAttribute('id')
        const imagesCarouselInstance = tns({
            container: `#${imagesCarouselId}`,
            mode: 'carousel',
            items: 1,
            gutter: 1,
            slideBy: 1,
            navAsThumbnails: true,
            loop: false,
            touch: true,
            mouseDrag: true,
            navContainer: document.querySelector('[aria-label="dots-'+ imagesCarouselId +'"]'),
            prevButton: document.querySelector('[aria-controls="prev-'+ imagesCarouselId +'"]'),
            nextButton: document.querySelector('[aria-controls="next-'+ imagesCarouselId +'"]'),
            nonce: window.customData.cspNonce
        })

        // Create thumbnails carousel
        const thumbnailsCarouselId = thumbnailsCarousel.getAttribute('id')
        const thumbnailsCarouselInstance = tns({
            container: `#${thumbnailsCarouselId}`,
            mode: 'carousel',
            items: 4.5,
            gutter: '16',
            slideBy: 1,
            nav: false,
            controls: false,
            loop: false,
            touch: true,
            mouseDrag: true,
            nonce: window.customData.cspNonce
        })

        const thumbnails = thumbnailsCarousel.querySelectorAll('[data-thumbnail]')
        if (thumbnails) {
            // Slide thumbnailsCarousel to current index
            imagesCarouselInstance.events.on('indexChanged', (info) => {
                thumbnailsCarouselInstance.goTo(info.index)
            })

            // Go to index when thumbnail is clicked
            thumbnails.forEach(thumbnail => {
                thumbnail.addEventListener('click', () => {
                    imagesCarouselInstance.goTo(thumbnail.dataset.index)
                })
            })
        }
    }

    static createConsumersFeature() {
        let carousels = document.querySelectorAll('[data-module="carousel-consumers-feature"]')
        let imageCarousels = document.querySelectorAll('[data-module="carousel-consumers-feature-images"]')
        let imageInstances = []
        let contentInstances = []

        if (!carousels.length || !imageCarousels.length) return

        imageCarousels.forEach((carousel) => {
            if (!carousel.querySelector('img')) {
                return
            }

            carousel = tns({
                container: carousel,
                mode: 'gallery',
                items: 1,
                nav: false,
                controls: false,
                loop: false,
                touch: false,
                nonce: window.customData.cspNonce
            })

            imageInstances.push(carousel)
        })

        carousels.forEach((carousel) => {
            const carouselId = carousel.getAttribute('id')

            carousel = tns({
                container: carousel,
                items: 1,
                autoHeight: true,
                nav: true,
                loop: false,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
                nonce: window.customData.cspNonce
            })

            contentInstances.push(carousel)
        })

        this.connectInstances(contentInstances, imageInstances)
    }

    static createTextMedia() {
        let carousels = document.querySelectorAll('[data-module="carousel-text-media"]')
        let imageCarousels = document.querySelectorAll('[data-module="carousel-text-media-images"]')
        let imageInstances = []
        let contentInstances = []

        imageCarousels.forEach((carousel) => {
            if (!carousel.querySelector('img')) {
                return
            }

            carousel = tns({
                container: carousel,
                mode: 'gallery',
                items: 1,
                nav: false,
                controls: false,
                loop: false,
                touch: false,
                nonce: window.customData.cspNonce
            })

            imageInstances.push(carousel)
        })

        carousels.forEach((carousel) => {
            const carouselId = carousel.getAttribute('id')

            carousel = tns({
                container: carousel,
                items: 1,
                autoHeight: true,
                nav: true,
                loop: false,
                navContainer: document.querySelector('[aria-label="dots-'+ carouselId +'"]'),
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
                nonce: window.customData.cspNonce
            })

            contentInstances.push(carousel)
        })

        this.connectInstances(contentInstances, imageInstances)
    }

    static createRelatedPosts() {
        let carousels = document.querySelectorAll('[data-module="carousel-related-posts"]')

        if (!carousels.length) return

        carousels.forEach((carousel) => {
            const carouselId = carousel.getAttribute('id')

            carousel = tns({
                container: carousel,
                fixedWidth: 240,
                gutter: 32,
                loop: false,
                nav: false,
                prevButton: document.querySelector('[aria-controls="prev-'+ carouselId +'"]'),
                nextButton: document.querySelector('[aria-controls="next-'+ carouselId +'"]'),
                responsive: {
                    1024: {
                        fixedWidth: 294,
                    }
                },
                nonce: window.customData.cspNonce
            })

            carousel.events.on('transitionEnd', (info) => {
                const { container } = info
                const thumb = this.getSliderProgressThumb(container)
                const { width: thumbWidth } = thumb.getBoundingClientRect()
                const { width: trackWidth } = container.getBoundingClientRect()
                const { width: containerWidth } = container.parentElement.getBoundingClientRect()
                const offset = this.getOffset(container)
                const percentage = Math.min(Math.floor(Math.abs((offset / (trackWidth - containerWidth)) * 100)), 100)
                const percentageOffset = Math.round((percentage / 100) * thumbWidth);

                thumb.style.left = `calc(${percentage}% - ${percentageOffset}px)`
            })
        })
    }
}

export default (function () {
    tinySliders.init()
})
