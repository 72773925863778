class AriaElement {
    constructor(element) {
        this.element = element
    }

    get titleExpanded() {
        return this.element.getAttribute('data-aria-title-expanded') || ''
    }

    get titleNotExpanded() {
        return this.element.getAttribute('data-aria-title-not-expanded') || ''
    }

    get titlePlaceholder() {
        return this.element.querySelector('[data-aria-title-placeholder]')
    }

    get hasDynamicTitle() {
        return this.titleExpanded && this.titleNotExpanded && this.titlePlaceholder
    }
}

export default AriaElement
