import { ariaCreateExpander, ariaToggle, ariaGetControlledElement } from '../utils/aria'

class languageSwitch {
    static toggle = document.querySelector('#language-switch-toggle')
    static panel = document.querySelector('#language-switch-panel')
    static popup = ariaGetControlledElement(this.toggle)
    static countrySelect = document.querySelector('[data-language-switch-country-select] select')
    static languageSelects = document.querySelectorAll('[data-language-switch-language-select]')
    static applyButton = document.querySelector('[data-language-switch-apply]')
    static closeButton = document.querySelector('[data-language-switch-close]')
    static languagePopupListenersAdded = false

    static init() {
        if (!this.toggle || !this.popup) return

        this.togglePopup()
        this.addEventListeners()
    }

    static addEventListeners() {
        this.clickOutsidePopup()
        this.clickCloseButton()
        this.escKey()
        this.handleChangeCountrySelect()
        this.handleClickApplyButton()
    }

    static togglePopup() {
        if (this.languagePopupListenersAdded) return

        ariaCreateExpander(this.toggle, {
            mode: 'attr',
            onToggle: () => {
                document.body.classList.toggle('overflow-hidden')
            }
        })

        this.languagePopupListenersAdded = true
    }

    static closePopup() {
        ariaToggle('expanded', this.toggle, 'true')
        this.popup.setAttribute('hidden', '')
        document.body.classList.remove('overflow-hidden')
    }

    static clickOutsidePopup() {
        document.addEventListener('click', event => {
            const isClickInside = this.panel.contains(event.target) || this.toggle.contains(event.target)

            if (!isClickInside && this.popupIsOpen()) {
                this.closePopup()
            }
        })
    }

    static escKey() {
        window.onkeydown = event => {
            if (event.keyCode === 27 && this.popupIsOpen()) {
                this.closePopup()
            }
        }
    }

    static popupIsOpen() {
        return !this.popup.hasAttribute('hidden')
    }

    static handleChangeCountrySelect() {
        if (!this.countrySelect || !this.languageSelects) return

        this.countrySelect.addEventListener('change', event => {
            this.languageSelects.forEach(select => {
                const countryCode = select.dataset.languageSwitchLanguageSelect
                if (countryCode === event.target.value) {
                    select.classList.remove('hidden')
                } else {
                    select.classList.add('hidden')
                }
            })
        })
    }

    static handleClickApplyButton() {
        if (!this.applyButton) return

        this.applyButton.addEventListener('click', () => {
            this.languageSelects.forEach(item => {
                if (!item.classList.contains('hidden')) {
                    const select = item.querySelector('select')
                    select.querySelectorAll('option').forEach(option => {
                        if (option.selected) {
                            const optionData = JSON.parse(option.getAttribute('data-option-data'))

                            if (optionData.url) {
                                window.location.href = optionData.url
                            }
                        }
                    })
                }
            })
        })
    }

    static clickCloseButton() {
        if (!this.closeButton) return

        this.closeButton.addEventListener('click', () => {
            this.closePopup()
        })
    }
}

export default (function () {
    languageSwitch.init()
})
