import HeaderSearchBox from '@vo/js/modules/HeaderSearchBox'

const searchPage = () => {
    const query = document.querySelector('.search__input')
    const entries = document.querySelector('.search__entries')
    const loadMore = document.querySelector('.search__load-more')
    let page = 1
    let isLoading = false
    let onLastPage = false

    if (!query || !entries || !loadMore) {
        return
    }

    loadMore.addEventListener('click', async () => {
        if (isLoading || onLastPage) {
            return
        }

        isLoading = true
        loadMore.classList.add('disabled')

        const url = new URL('/api/search', window.location.origin)
        url.searchParams.set('q', query.value)
        url.searchParams.set('page', page + 1)
        // TODO: Add support for terms

        try {
            const response = await fetch(url)
            const json = await response.json()

            if (!json.nextPage) {
                onLastPage = true
                loadMore.classList.add('hidden')
            }

            if (json.data.length) {
                page++
                entries.innerHTML += json.data
            }
        } catch (e) {

        }

        loadMore.classList.remove('disabled')
        isLoading = false
    })
}

export default (function () {
    searchPage()
    new HeaderSearchBox(document.querySelector('#search'))
})
